const getSpeedTestKeys = (speedTest) => speedTest.result.resultManufacturer.results.map((result) => result.func);

export const getSpeedTestAverage = (speedTests) => {
    if (!speedTests || speedTests.length === 0) return { res: {}, totalTests: 0 };

    const finishedSpeedTests = speedTests.filter((test) => test.status === "finished");
    const latestHasErrors =
        finishedSpeedTests.length > 0 &&
        finishedSpeedTests[0].result.resultManufacturer.results.some((res) => res.error);
    const nonErrorSpeedTests = finishedSpeedTests.filter((test) =>
        test.result.resultManufacturer.results.every((result) => !result.error),
    );
    const latest = nonErrorSpeedTests[0];
    const latestKeys = getSpeedTestKeys(latest);
    const filteredSpeedTests = nonErrorSpeedTests.filter((test) =>
        latestKeys.every((key) => getSpeedTestKeys(test).includes(key)),
    );
    const totalTests = filteredSpeedTests.length;

    const res = {
        results: [],
        totalTime: 0,
    };

    const totalTimeAverage = filteredSpeedTests.reduce(
        (acc, test) => acc + test.result.resultManufacturer.totalTime,
        0,
    );

    res.totalTime = totalTimeAverage / totalTests;

    latest.result.resultManufacturer.results.forEach((result) => {
        const func = result.func;
        const total = filteredSpeedTests.reduce((acc, test) => {
            const res = test.result.resultManufacturer.results.find((r) => r.func === func);
            return acc + res.time;
        }, 0);
        const average = total / totalTests;

        res.results.push({
            func,
            time: average,
            error: false,
        });
    });

    return { totalTests, res, latestHasErrors };
};
