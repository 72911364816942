import { useEffect, useMemo, useState } from "react";

import useCollection from "../../../helpers/useCollection";

import Spinner from "../../../components/Spinner";
import PageError from "../../../components/PageError";
import ResultView from "../SpeedTest/ResultView";
import { getSpeedTestAverage } from "./helpers/utils";
import CustomPage from "../../../components/CustomPage";
import useUser from "../../../helpers/useUser";
import { EuiButtonGroup, EuiFieldText, EuiFlexGroup, EuiFormRow, EuiSpacer, EuiText } from "@elastic/eui";
import { db } from "../../../helpers/firebase";

const SpeedTestCompare = ({ manufacturerUrl }) => {
    const [selectedReference, setSelectedReference] = useState(null);
    const [manufacturers, setManufacturers] = useState([]);
    const [limit, setLimit] = useState(20);
    const [limitText, setLimitText] = useState(limit.toString());

    const { data: userData } = useUser();

    const referenceCollection = useMemo(
        () => `publicManufacturers/${selectedReference}/speedTests`,
        [selectedReference],
    );

    useEffect(() => {
        if (userData?.isAdmin) {
            const fetchManufacturers = async () => {
                const collection = await db.collection("publicManufacturers").get();

                setManufacturers(collection.docs.map((doc) => doc.id).filter((m) => m !== manufacturerUrl));
            };

            fetchManufacturers();
        } else {
            setManufacturers((userData?.manufacturers || []).filter((m) => m !== manufacturerUrl));
        }
    }, [userData, manufacturerUrl]);

    useEffect(() => {
        if (!selectedReference && manufacturers.length > 0) setSelectedReference(manufacturers[0]);
    }, [manufacturers, selectedReference]);

    const [loadingManufacturer, errorManudacturer, speedTestsManufacturer] = useCollection(
        `publicManufacturers/${manufacturerUrl}/speedTests`,
        "createdAt",
        limit,
    );
    const [loadingReference, errorReference, speedTestsReference] = useCollection(
        referenceCollection,
        "createdAt",
        limit,
    );

    const loading = loadingManufacturer || loadingReference;
    const error = errorManudacturer || errorReference;

    if (loading) return <Spinner />;
    if (error) return <PageError title="Something went wrong" />;
    if (!selectedReference) return <p>No reference</p>;

    if (!speedTestsManufacturer || speedTestsManufacturer.length === 0) {
        return <p>No speed tests found</p>;
    }

    const latestHasError =
        Array.isArray(speedTestsManufacturer) &&
        speedTestsManufacturer.length > 0 &&
        speedTestsManufacturer[0].result.resultManufacturer.results.some((res) => res.error);

    if (latestHasError) {
        return (
            <CustomPage>
                <EuiText>
                    <h1>Latest speed test has an error</h1>
                    <h2>Please check the latest speed test and try again</h2>
                </EuiText>
            </CustomPage>
        );
    }

    const manufacturerAverage = getSpeedTestAverage(speedTestsManufacturer);
    const referenceAverage = getSpeedTestAverage(speedTestsReference);

    const speedTest = {
        result: {
            resultManufacturer: manufacturerAverage.res,
            resultReference: referenceAverage.res,
        },
    };

    return (
        <>
            <EuiFlexGroup justifyContent="center" alignItems="center" direction="column">
                <EuiText>
                    <h1>Compare {manufacturerUrl} with</h1>
                </EuiText>

                <EuiButtonGroup
                    legend="Manufacturers"
                    options={manufacturers.map((man) => ({ id: man, label: man }))}
                    idSelected={selectedReference}
                    onChange={(id) => setSelectedReference(id)}
                />

                <EuiFlexGroup justifyContent="center" alignItems="flexEnd">
                    <EuiFormRow label="Limit">
                        <EuiFieldText
                            value={limitText}
                            onChange={(e) => setLimitText(e.target.value)}
                            onBlur={() => setLimit(parseInt(limitText) || 20)}
                            onKeyUp={(e) => e.key === "Enter" && setLimit(parseInt(limitText) || 20)}
                            placeholder="Limit"
                        />
                    </EuiFormRow>

                    <EuiText>
                        <p>Showing average from last {limit} available tests</p>
                    </EuiText>
                </EuiFlexGroup>
            </EuiFlexGroup>

            <EuiSpacer size="xl" />

            {referenceAverage.latestHasErrors ? (
                <CustomPage>
                    <EuiText>
                        <h1>Latest speed test for {selectedReference} has an error</h1>
                        <h2>Please check the latest speed test and try again</h2>
                    </EuiText>
                </CustomPage>
            ) : (
                <>
                    <CustomPage>
                        {manufacturerAverage.totalTests > 0 && referenceAverage.totalTests > 0 ? (
                            <ResultView
                                speedTest={speedTest}
                                manufacturerUrl={manufacturerUrl}
                                referenceTitle={selectedReference}
                                totalTestsManufacturer={manufacturerAverage.totalTests}
                                totalTestsReference={referenceAverage.totalTests}
                            />
                        ) : (
                            <div style={{ display: "flex", flexDirection: "column", gap: 4, marginTop: 16 }}>
                                <p>Not enough data to compare</p>
                                <p>
                                    {manufacturerUrl} tests: {manufacturerAverage.totalTests}
                                </p>
                                <p>
                                    {selectedReference} tests: {referenceAverage.totalTests}
                                </p>
                            </div>
                        )}
                    </CustomPage>
                </>
            )}
        </>
    );
};

export default SpeedTestCompare;
