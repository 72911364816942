import React, { Fragment } from 'react';
import { Route, Switch } from "react-router-dom";
import { useLocation, useHistory } from 'react-router';
import { EuiButton, EuiHeader, EuiHeaderLinks, EuiHeaderLink, EuiText, EuiSpacer } from '@elastic/eui';

import Login from './Login';
import useUser from '../../helpers/useUser';
import SpeedTest from './SpeedTest';
import AuthLayout from './layouts/AuthLayout';
import HeaderUserMenu from './HeaderUserMenu';
import Home from './Home';
import SpeedTestCompare from './SpeedTestCompare';

const Manufacturer = ({ match }) => {
    const { user, data: userData } = useUser();

    const location = useLocation();
    const history = useHistory();

    const url = `/${match.params.manufacturerUrl}`;

    const Link = ({ to, children }) => (
        <EuiHeaderLink
            isActive={location.pathname === `${match.url}${to}`}
            onClick={() => history.push(`${url}${to}`)}
        >
            {children}
        </EuiHeaderLink>
    )

    const hasAccess = userData && Array.isArray(userData.manufacturers) && userData.manufacturers.some(man => man === match.params.manufacturerUrl);
    const isAdmin = userData?.isAdmin;
    const hasCompareAccess = isAdmin || (userData && Array.isArray(userData.manufacturers) && userData.manufacturers.length > 1);

    return (
        <Fragment>
            {user && (hasAccess || isAdmin) && (
                <EuiHeader
                    position="static"
                    sections={[
                        {
                            items: [
                                <EuiHeaderLinks>
                                    <Link to="" >Home</Link>
                                    <Link to="/speed-test" >Speed test</Link>
                                    {hasCompareAccess && (
                                        <Link to="/speed-test-compare" >Speed test compare</Link>
                                    )}
                                </EuiHeaderLinks>,
                            ],
                        },
                        {
                            items: [
                                <HeaderUserMenu />,
                            ],
                        }
                    ]}
                />
            )}

            <Switch>
                <Route exact path={match.path} component={Home} />
                <Route path={`${match.path}/login`} component={Login} />
                <Route path={`${match.path}/speed-test`} component={(props) => <AuthLayout {...props} Component={SpeedTest} />} />
                <Route path={`${match.path}/speed-test-compare`} component={(props) => <AuthLayout {...props} Component={SpeedTestCompare} />} />

                <Route path="*">
                    <EuiText textAlign="center" >
                        <h2>404 Page Not Found</h2>
                        <h3>No match for <code>{location.pathname}</code></h3>
                        <EuiSpacer />
                        <EuiButton onClick={() => history.push(url)} >Go home</EuiButton>
                    </EuiText>
                </Route>
            </Switch>
        </Fragment>
    );
}

export default Manufacturer;
