import React, { useState, useEffect } from "react";
import {
    EuiButton,
    EuiText,
    EuiHorizontalRule,
    EuiFlexGroup,
    EuiFlexItem,
    EuiAccordion,
    EuiSpacer,
    EuiTextColor,
} from "@elastic/eui";
import moment from "moment";

import { db, functions } from "../../../helpers/firebase";
import CustomPage from "../../../components/CustomPage";
import useCollection from "../../../helpers/useCollection";
import Spinner from "../../../components/Spinner";
import PageError from "../../../components/PageError";
import { Fragment } from "react";
import ResultView from "./ResultView";
import useUser from "../../../helpers/useUser";

const formatDate = (date) => moment(date.seconds * 1000).format("DD.MM.YYYY HH:mm:ss");

const SpeedTest = ({ manufacturerUrl }) => {
    const [loading, error, speedTests] = useCollection(
        `publicManufacturers/${manufacturerUrl}/speedTests`,
        "createdAt",
        20,
    );
    const [runningCheck, setRunningCheck] = useState(false);
    const [momentNow, setMomentNow] = useState(moment());
    const [errorSpeedCheck, setErrorSpeedCheck] = useState(null);
    const [loadingClear, setLoadingClear] = useState(false);
    const [errorClear, setErrorClear] = useState(null);

    const { data: userData } = useUser();

    useEffect(() => {
        const interval = setInterval(() => setMomentNow(moment()), 1000);

        return () => clearInterval(interval);
    }, []);

    const handleSpeedCheck = async () => {
        setRunningCheck(true);
        setErrorSpeedCheck(null);

        const callable = functions.httpsCallable("v2-runSpeedTest");

        try {
            const res = await callable({ url: manufacturerUrl });

            if (res?.data?.error) setErrorSpeedCheck(res.data.error);
        } catch (error) {
            console.log(error);
            setErrorSpeedCheck("general");
        }

        setRunningCheck(false);
    };

    const handleClearSpeedCheck = async () => {
        setLoadingClear(true);
        setErrorClear(null);

        try {
            await db.collection(`publicManufacturers/${manufacturerUrl}/speedTests`).doc(speedTests[0].id).delete();
        } catch (error) {
            console.log(error);
            setErrorClear("general");
        }

        setLoadingClear(false);
    };

    if (loading) return <Spinner />;
    if (error) return <PageError title="Something went wrong" />;

    const hasSpeedTests = Array.isArray(speedTests) && speedTests.length > 0;
    const waitSeconds = 60;

    const latestSpeedTest = hasSpeedTests ? speedTests[0] : null;
    const latestSpeedTestUpate = latestSpeedTest?.updatedAt ? moment(latestSpeedTest.updatedAt.seconds * 1000) : null;
    const latestSpeedTestCreate = latestSpeedTest?.createdAt ? moment(latestSpeedTest.createdAt.seconds * 1000) : null;
    const latestUpdateDiff = latestSpeedTestUpate ? moment.duration(momentNow.diff(latestSpeedTestUpate)) : null;
    const latestCreateDiff = latestSpeedTestCreate ? moment.duration(momentNow.diff(latestSpeedTestCreate)) : null;
    const isRunning = hasSpeedTests && latestSpeedTest?.status === "started";
    const waitToRun = hasSpeedTests && latestSpeedTestUpate && latestUpdateDiff.asSeconds() < waitSeconds;
    const canClearLatest = hasSpeedTests && latestSpeedTestCreate && latestCreateDiff.asSeconds() > waitSeconds;
    const restSpeedTests = hasSpeedTests ? speedTests.slice(1) : [];

    return (
        <CustomPage>
            <EuiFlexGroup justifyContent="center" alignItems="center">
                <EuiFlexItem>
                    {latestSpeedTest && (
                        <EuiText size="xs" color="subdued">
                            Started: {formatDate(latestSpeedTest.createdAt)}
                        </EuiText>
                    )}
                    {latestSpeedTest?.updatedAt && (
                        <EuiText size="xs" color="subdued">
                            Updated: {formatDate(latestSpeedTest.updatedAt)}
                        </EuiText>
                    )}
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiButton
                        disabled={runningCheck || isRunning || waitToRun}
                        isLoading={runningCheck}
                        onClick={handleSpeedCheck}
                    >
                        Run new speed test
                    </EuiButton>

                    {errorSpeedCheck && (
                        <EuiText textAlign="center">
                            <EuiTextColor color="danger">
                                {errorSpeedCheck === "forbidden" ? "Forbidden" : "Something went wrong"}
                            </EuiTextColor>
                        </EuiText>
                    )}
                </EuiFlexItem>

                <EuiFlexItem>
                    {waitToRun && (
                        <EuiText color="subdued" size="xs" textAlign="right">
                            Please wait {(waitSeconds - latestUpdateDiff.asSeconds()).toFixed(0)} seconds to run again
                        </EuiText>
                    )}
                </EuiFlexItem>
            </EuiFlexGroup>

            {isRunning && (
                <Fragment>
                    <EuiHorizontalRule />

                    {canClearLatest && userData?.isAdmin && (
                        <EuiText textAlign="center">
                            <EuiText style={{ marginBottom: 16 }}>
                                Speed test has been running for more than {waitSeconds} seconds. You can clear the
                                running speed test if you want to run a new one.
                            </EuiText>
                            <EuiButton disabled={loadingClear} isLoading={loadingClear} onClick={handleClearSpeedCheck}>
                                Clear running speed test
                            </EuiButton>

                            {errorClear && (
                                <EuiText textAlign="center">
                                    <EuiTextColor color="danger">Something went wrong</EuiTextColor>
                                </EuiText>
                            )}
                        </EuiText>
                    )}

                    <Spinner text="Speed test is running. This can take a while." />
                </Fragment>
            )}

            {latestSpeedTest?.result && (
                <Fragment>
                    <EuiHorizontalRule />

                    <ResultView speedTest={latestSpeedTest} manufacturerUrl={manufacturerUrl} />
                </Fragment>
            )}

            {restSpeedTests.length > 0 && (
                <Fragment>
                    <EuiSpacer />

                    <EuiText textAlign="center">
                        <h2>Previous speed tests</h2>
                    </EuiText>

                    <EuiSpacer />

                    {restSpeedTests.map((speedTest) => {
                        const isError = speedTest.status === "error";
                        let buttonContent = `${formatDate(speedTest.updatedAt)} `;

                        if (isError) {
                            buttonContent += "(error)";
                        } else {
                            buttonContent += `(total time: ${speedTest.result?.resultManufacturer.totalTime.toFixed(0)})`;
                        }

                        return (
                            <Fragment key={speedTest.id}>
                                <EuiAccordion id={speedTest.id} buttonContent={buttonContent} paddingSize="l">
                                    {isError ? (
                                        <Fragment>
                                            {speedTest.error?.status && <p>Status: {speedTest.error.status}</p>}
                                            {speedTest.error?.data?.msgKey && (
                                                <p>Message key: {speedTest.error.data.msgKey}</p>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <ResultView speedTest={speedTest} manufacturerUrl={manufacturerUrl} />
                                    )}
                                </EuiAccordion>

                                <EuiSpacer />
                            </Fragment>
                        );
                    })}
                </Fragment>
            )}
        </CustomPage>
    );
};

export default SpeedTest;
